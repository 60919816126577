import React, { useState, useEffect } from "react"
import axios from "axios"
import { Form, Col } from "react-bootstrap"
import Alert from "react-bootstrap/Alert"

const TYPE = ["image", "iframe"]
const TIMEOUT = 3000

const List = (props) => {
	const [setting, setSetting] = useState({
		bt_text: "ดูเพิ่มเติม คลิก",
		bt_text_color: "#ffffff",
		bt_color: "#E86E2E",
		bt_bg_color: "#003399",
	})
	const [desktopType, setDesktopType] = useState({
		type: "image",
		image: "https://media.thairath.co.th/image/fRt5DUD49rLlmBBU5Wmqc14HEKrjFlnYziHeIA5dIs1a2hMe9vA1.jpg",
		iframe: "",
	})
	const [desktopAlert, setDesktopAlert] = useState(false)
	const [desktopHtml, setDesktopHtml] = useState("")

	useEffect(() => {
		setDesktopHtml(`<style type="text/css">
    @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400&display=swap");
    .btn-box {
      display: flex;
      gap: 20px;
      margin-top: 30px;
      justify-content: center;
      flex-wrap: wrap;
    }
    .btn-box a {
      border: 0px;
      padding: 6px 20px;
      color: ${setting.bt_text_color};
      background-color: ${setting.bt_color};
      border-radius: 20px;
      width: 100%;
      max-width: 225px;
      font-size: 18px;
      font-family: "Kanit", sans-serif;
      text-decoration: none;
      text-align: center;
    }
    .landingpage-box {
      width: 100%;
      text-align: center;
    }
    .video-box {
      display: grid;
      justify-content: center;
    }
  </style>
  <div class="video-box">
    <!-- แปะลิ้ง video ที่ได้มาหลังอัพโหลด -->
    <a href="%%CLICK_URL_UNESC%%%%DEST_URL%%" target="_blank">
    ${desktopType.type === "image" ? `<img src="${desktopType.image}"/>` : ""}
    ${desktopType.type === "iframe" ? `${desktopType.iframe}` : ""}
    </a>
  </div>
  <div class="btn-box">
    <a href="%%CLICK_URL_UNESC%%%%DEST_URL%%"
      target="_blank"
      id="clickBBL_desktop">
      ${setting.bt_text}
    </a>
    <a href="https://www.thairath.co.th/home"
      target="_parent"
      title="เข้าสู่เว็บ ไทยรัฐ">
      เข้าสู่เว็บ ไทยรัฐ
    </a>
  </div>
  `)
	}, [setting, desktopType])

	const [mobileType, setMobileType] = useState({
		type: "image",
		image: "https://media.thairath.co.th/image/fRt5DUD49rLlmBBU5Wmqc14HEKrjFlnYziHeIA5dIs1a2hMe9vA1.jpg",
		iframe: "",
	})
	const [mobileAlert, setMobileAlert] = useState(false)
	const [mobileHtml, setMobileHtml] = useState("")

	useEffect(() => {
		setMobileHtml(`<style type="text/css">
    @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@400&display=swap");
    .btn-box {
      display: flex;
      gap: 20px;
      margin-top: 30px;
      justify-content: center;
      flex-wrap: wrap;
    }
    .btn-box a {
      border: 0px;
      padding: 6px 20px;
      color: ${setting.bt_text_color};
      background-color: ${setting.bt_color};
      border-radius: 20px;
      width: 100%;
      max-width: 225px;
      font-size: 18px;
      font-family: "Kanit", sans-serif;
      text-decoration: none;
      text-align: center;
    }
    .landingpage-box {
      width: 100%;
      text-align: center;
    }
    .video-box {
      display: grid;
      justify-content: center;
    }
  </style>
  <div class="video-box">
    <!-- แปะลิ้ง video ที่ได้มาหลังอัพโหลด -->
    <a href="%%CLICK_URL_UNESC%%%%DEST_URL%%" target="_blank">
    ${mobileType.type === "image" ? `<img src="${mobileType.image}"/>` : ""}
    ${mobileType.type === "iframe" ? `${mobileType.iframe}` : ""}
    </a>
  </div>
  <div class="btn-box">
    <a href="%%CLICK_URL_UNESC%%%%DEST_URL%%"
      target="_blank"
      id="clickBBL_desktop">
      ${setting.bt_text}
    </a>
    <a href="https://www.thairath.co.th/home"
      target="_parent"
      title="เข้าสู่เว็บ ไทยรัฐ">
      เข้าสู่เว็บ ไทยรัฐ
    </a>
  </div>
  `)
	}, [setting, mobileType])

	const [previewUrl, setPreviewUrl] = useState("https://demo5.thairathaws.store/landing/preview?bg=003399")
	useEffect(() => {
		setPreviewUrl(`https://demo5.thairathaws.store/landing/preview?bg=${setting.bt_bg_color.replace("#", "")}`)
	}, [setting, previewUrl])

	const handlePreview = () => {
		window.open(previewUrl, "_blank")
	}

	const handleCopy = (value, type) => {
		if (value && type) {
			navigator.clipboard.writeText(value)
			switch (type) {
				case "desktop":
					setDesktopAlert(true)
					break
				case "mobile":
					setMobileAlert(true)
					break
				default:
			}
		}
	}

	const handleChange = (e, name) => {
		const value = e.target.value
		if (name) {
			setSetting((prev) => {
				const data = { ...prev }
				data[name] = value
				return data
			})
		}
	}

	const handleChangeType = (e, type, name) => {
		const value = e.target.value
		if (type && name) {
			switch (type) {
				case "desktop":
					setDesktopType((prev) => {
						const data = { ...prev }
						data[name] = value
						return data
					})
					break
				case "mobile":
					setMobileType((prev) => {
						const data = { ...prev }
						data[name] = value
						return data
					})
					break
				default:
			}
		}
	}

	const handleUpload = async (e, type) => {
		const file = e.target.files[0]
		if (file) {
			const formData = new FormData()
			formData.append("image_files", file)
			formData.append("ref_id", "BBL")
			try {
				const response = await axios.post(`${process.env.REACT_APP_IMAGE_HOST}`, formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				if (response.data.status === 200) {
					if (response.data.data) {
						switch (type) {
							case "desktop":
								setDesktopType((prev) => {
									const data = { ...prev }
									data["image"] = response.data.data.items[0].url
									return data
								})
								break
							case "mobile":
								setMobileType((prev) => {
									const data = { ...prev }
									data["image"] = response.data.data.items[0].url
									return data
								})
								break
							default:
						}
					}
				}
			} catch (e) {
				console.error(e)
			}
		}
	}

	useEffect(() => {
		if (desktopAlert) {
			setTimeout(() => {
				setDesktopAlert(false)
			}, TIMEOUT)
		}
		if (mobileAlert) {
			setTimeout(() => {
				setMobileAlert(false)
			}, TIMEOUT)
		}
	}, [desktopAlert, mobileAlert])

	return (
		<div className="card shadow mb-4">
			<div className="card-header py-3">
				<h6 className="m-0 font-weight-bold text-primary">Generate Script</h6>
			</div>
			<div className="card-body">
				{/* //* desktop */}
				<div class="container text-center">
					<div className="row">
						<div className="col">
							<Form>
								<Form.Row>
									<label>
										<strong>Generate Preview Link</strong>
									</label>
								</Form.Row>
								<Form.Row>
									<div className="col-md-3 align-items-center mb-3">
										<Form.Control type="color" value={setting.bt_bg_color} name="bt_bg_color" onChange={(e) => handleChange(e, "bt_bg_color")} />
									</div>
									<div className="d-flex justify-content-between align-items-center mb-3">
										<button type="button" onClick={handlePreview} className="btn btn-primary">
											Generate Preview Link
										</button>
									</div>
								</Form.Row>
								<hr className="mb-5"></hr>
								<Form.Row>
									<label>
										<strong>Generate Script</strong>
									</label>
								</Form.Row>
								<Form.Row>
									<Col>
										<Form.Group>
											<Form.Label>สีข้อความปุ่ม</Form.Label>
											<Form.Control type="color" value={setting.bt_text_color} name="bt_text_color" onChange={(e) => handleChange(e, "bt_text_color")} />
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label>สีพื้นหลังปุ่ม</Form.Label>
											<Form.Control type="color" value={setting.bt_color} name="bt_color" onChange={(e) => handleChange(e, "bt_color")} />
										</Form.Group>
									</Col>
									<Col>
										<Form.Group>
											<Form.Label>ข้อความปุ่ม</Form.Label>
											<Form.Control placeholder="กรุณากรอกข้อความปุ่ม" value={setting.bt_text} name="bt_text" onChange={(e) => handleChange(e, "bt_text")} />
										</Form.Group>
									</Col>
								</Form.Row>
								<Form.Row>
									<Col>
										<Form.Group>
											<Form.Label>รูปแบบ</Form.Label>
											<Form.Control as="select" value={desktopType.type} name="desktopType" onChange={(e) => handleChangeType(e, "desktop", "type")}>
												{TYPE &&
													TYPE.map((_el, _index) => {
														return (
															<option key={_el} value={_el}>
																{_el}
															</option>
														)
													})}
											</Form.Control>
										</Form.Group>
									</Col>
									<Col>
										{desktopType.type === "image" && (
											<Form.Group>
												<Form.Label>รูปภาพ</Form.Label>
												<Form.Control type="file" onChange={(e) => handleUpload(e, "desktop")} className="border py-1 px-3" />
											</Form.Group>
										)}
										{desktopType.type === "iframe" && (
											<Form.Group>
												<Form.Label>iframe</Form.Label>
												<Form.Control as="textarea" rows={3} value={desktopType.iframe} onChange={(e) => handleChangeType(e, "desktop", "iframe")} />
											</Form.Group>
										)}
									</Col>
								</Form.Row>
							</Form>
						</div>
					</div>
					<div class="row align-items-start">
						<div class="col">
							<Form>
								<Form.Group className="mb-3">
									{desktopAlert === true && (
										<Alert variant={"success"} onClose={() => setDesktopAlert(false)} dismissible>
											<p>คัดลอกเรียบร้อย</p>
										</Alert>
									)}
									<div className="d-flex justify-content-between align-items-center mb-3">
										<Form.Label>Desktop Script</Form.Label>
										<button type="button" onClick={() => handleCopy(desktopHtml, "desktop")} className="btn btn-success">
											<span>
												<i class="far fa-clipboard"></i>
											</span>
										</button>
									</div>
									<Form.Control as="textarea" rows={20} value={desktopHtml} />
								</Form.Group>
							</Form>
						</div>
						<div className="col">
							<div
								dangerouslySetInnerHTML={{
									__html: desktopHtml,
								}}
							/>
						</div>
					</div>
				</div>
				{/* //* mobile */}
				<div class="container text-center mt-5">
					<div className="row">
						<div className="col">
							<Form>
								<Form.Row>
									<Col>
										<Form.Group>
											<Form.Label>รูปแบบ</Form.Label>
											<Form.Control as="select" value={mobileType.type} name="desktopType" onChange={(e) => handleChangeType(e, "mobile", "type")}>
												{TYPE &&
													TYPE.map((_el, _index) => {
														return (
															<option key={_el} value={_el}>
																{_el}
															</option>
														)
													})}
											</Form.Control>
										</Form.Group>
									</Col>
									<Col>
										{mobileType.type === "image" && (
											<Form.Group>
												<Form.Label>รูปภาพ</Form.Label>
												<Form.Control type="file" onChange={(e) => handleUpload(e, "mobile")} className="border py-1 px-3" />
											</Form.Group>
										)}
										{mobileType.type === "iframe" && (
											<Form.Group>
												<Form.Label>iframe</Form.Label>
												<Form.Control as="textarea" rows={3} value={mobileType.iframe} onChange={(e) => handleChangeType(e, "mobile", "iframe")} />
											</Form.Group>
										)}
									</Col>
								</Form.Row>
							</Form>
						</div>
					</div>
					<div class="row align-items-start">
						<div class="col">
							<Form>
								<Form.Group className="mb-3">
									{mobileAlert === true && (
										<Alert variant={"success"} onClose={() => setMobileAlert(false)} dismissible>
											<p>คัดลอกเรียบร้อย</p>
										</Alert>
									)}
									<div className="d-flex justify-content-between align-items-center mb-3">
										<Form.Label>Mobile Script</Form.Label>
										<button type="button" onClick={() => handleCopy(mobileHtml, "mobile")} className="btn btn-success">
											<span>
												<i class="far fa-clipboard"></i>
											</span>
										</button>
									</div>
									<Form.Control as="textarea" rows={20} value={mobileHtml} />
								</Form.Group>
							</Form>
						</div>
						<div className="col">
							<div
								dangerouslySetInnerHTML={{
									__html: mobileHtml,
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default List
