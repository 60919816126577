import React from "react"

import Home from "../components/Home"
import Login from "../components/login/View"
import Dashboard from "../components/Dashboard"
import Dashboard2 from "../components/Dashboard2"
import GroupReport from "../components/report/GroupReport"
import PersonsReport from "../components/report/PersonsReport"
import MemberList from "../components/member/List"
import MemberForm from "../components/member/Edit"

import SaleAdsList from "../components/Sale/List"

import SaleAdsSettingList from "../components/Sale/adsSetting/List"
import SaleAdsSettingForm from "../components/Sale/adsSetting/Form"
import SaleGenerateScriptList from "../components/Sale/generateScript/List"

import Journey from "../components/report/Journey"
import ListWaiting from "../components/content/ListWaiting"
import ListApprove from "../components/content/ListApprove"
import UserList from "../components/user/List"
import UserForm from "../components/user/Form"
import ChangePassword from "../components/profile/Passwords"

import NotificationList from "../components/notificationSetup/List"
import NotificationSetup from "../components/notificationSetup/NotificationSetup"

import User from "../libs/admin"

import CommentList from "../components/Comment/List"
import ToppicList from "../components/Comment/Topic"

import CampaignList from "../components/Campaign/List"
import CampaignForm from "../components/Campaign/StepForm"
import CampaignPageSetting from "../components/Campaign/Pages/CampaignPageSetting"

import SubscriptionPackageList from "../components/subscription/package/List"
import SubscriptionPackageForm from "../components/subscription/package/Form"

import SubscriptionMemberList from "../components/subscription/members/List"
import SubscriptionTransactionList from "../components/subscription/transaction/List"
import SubscriptionTransactionView from "../components/subscription/transaction/Form"

import { Route } from "react-router-dom"
import ActivityVote from "../components/CampaignNew/templates/ActivityVote/Manage"
import ActivityVoteList from "../components/CampaignNew/templates/ActivityVote"
import VoteInArticleList from "../components/CampaignNew/templates/VoteInArticle"
import VoteInArticle from "../components/CampaignNew/templates/VoteInArticle/Manage"
import NewsVoteList from "../components/CampaignNew/templates/NewsVote"
import NewsVote from "../components/CampaignNew/templates/NewsVote/Manage"

import EuroFootball from "../components/CampaignNew/templates/Eurofootball"
import EuroFootballSetup from "../components/CampaignNew/templates/Eurofootball/tabs"
import EuroFootballDashboard from "../components/CampaignNew/templates/Eurofootball/dashboard"

import MongkolWallDashboard from "../components/MongkolWall/Dashboard"
import BoontanjaiDashboard from "../components/Boontanjai/Dashboard"

import MemberDashboard from "../components/MemberManage/Dashboard"

const Routing = () => {
	const user = User.getCookieData()

	return (
		<>
			<Route exact path="/" component={Home} />
			<Route exact path="/login" component={Login} />
			<Route exact path="/dashboard" component={Dashboard} />
			<Route exact path="/dashboard2" component={Dashboard2} />
			<Route exact path="/report/group" component={GroupReport} />
			<Route exact path="/report/persons" component={PersonsReport} />
			<Route exact path="/report/journey" component={Journey} />
			<Route exact path="/member/list" component={MemberList} />
			<Route exact path="/member/edit/:id" component={MemberForm} />

			<Route exact path="/sale/ads" component={SaleAdsList} />
			<Route exact path="/sale/ads-setting" component={SaleAdsSettingList} />
			<Route exact path="/sale/ads-setting/form" component={SaleAdsSettingForm} />
			<Route exact path="/sale/ads-setting/edit/:id" component={SaleAdsSettingForm} />
			<Route exact path="/sale/generate-script" component={SaleGenerateScriptList} />

			<Route exact path="/content/list/waiting" component={ListWaiting} />
			<Route exact path="/content/list/approve" component={ListApprove} />

			<Route exact path="/comment/list" component={CommentList} />
			<Route exact path="/comment/topic/:id" component={ToppicList} />

			<Route exact path="/profile/password" component={ChangePassword} />
			{user.position === "admin" ? (
				<>
					<Route exact path="/user/list" component={UserList} />
					<Route exact path="/user/edit/:id" component={UserForm} />
					<Route exact path="/notificationcenter/list" component={NotificationList} />
					<Route exact path="/notificationcenter/edit/:id" component={NotificationSetup} />
				</>
			) : (
				""
			)}

			<Route exact path="/campaign/list" component={CampaignList} />
			<Route exact path="/campaign/form" component={CampaignForm} />
			<Route exact path="/campaign/edit/:id" component={CampaignForm} />
			<Route exact path="/campaign/page-setting" component={CampaignPageSetting} />

			<Route exact path="/campaign-new/activity-vote" component={ActivityVoteList} />
			<Route exact path="/campaign-new/activity-vote/manage/:id?" component={ActivityVote} />

			<Route exact path="/campaign-new/vote-in-article" component={VoteInArticleList} />
			<Route exact path="/campaign-new/vote-in-article/manage/:id?" component={VoteInArticle} />

			<Route exact path="/campaign-new/news-vote" component={NewsVoteList} />
			<Route exact path="/campaign-new/news-vote/manage/:id?" component={NewsVote} />

			<Route exact path="/campaign-new/euro-football" component={EuroFootball} />
			<Route exact path="/campaign-new/euro-football/manage/:id?" component={EuroFootballSetup} />
			<Route exact path="/campaign-new/euro-football/dashboard/:id?" component={EuroFootballDashboard} />

			<Route exact path="/subscription/package/list" component={SubscriptionPackageList} />
			<Route exact path="/subscription/package/edit/:id" component={SubscriptionPackageForm} />

			<Route exact path="/subscription/member/list" component={SubscriptionMemberList} />

			<Route exact path="/subscription/transaction/list" component={SubscriptionTransactionList} />

			<Route exact path="/subscription/transaction/view/:id" component={SubscriptionTransactionView} />

			<Route exact path="/mongkol-wall/dashboard" component={MongkolWallDashboard} />

			<Route exact path="/boontanjai/dashboard" component={BoontanjaiDashboard} />

			<Route exact path="/member/dashboard" component={MemberDashboard} />
			{/* <Route path="/logout" component={Logout} /> */}
			{/* <Route path="/data" component={CandidatePage} />
      <Route path="/announcement" component={AnnouncementPage} />
      <Route path="/job" component={Job} />
      <Route path="/form" component={Addjobdata} />
      <Route path="/changePassword" component={ChangePasswordPage} />
      <Route path="/edit/:id" component={EditPage} />
      <Route path="/show/:id" component={ShowCandidatePage} />

      <Route exact path="/department" component={DepartmentPage} />
      <Route path="/department/edit/:id"  component={EditDepartmentPage} />
      <Route path="/department/add"  component={AddDepartmentPage} />

      <Route exact path="/panorama"  component={PanoramaPage} />
      <Route exact path="/panorama/form"  component={FormPanoramaPage} />
      <Route exact path="/panorama/form/:id"  component={FormPanoramaPage} /> */}
		</>
	)
}

export default Routing
